html, body, #root, .App {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: row;
}

.editor {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.editor .ReactCodeMirror {
    flex: 1;
}

.editor .CodeMirror {
    height: 100%;
}

.preview {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
}

.preview__image {
    border: 1px solid black;
    position: relative;
    line-height: 0;
}

.preview__grid {
    position: absolute;
    top: 0;
    left: 0;
}